import React, { useState, useEffect } from "react";
import { Button, Card, Input, InputGroup, InputGroupText } from "reactstrap";
import {  BiSearch } from "react-icons/bi";
import { DataGrid } from "@mui/x-data-grid";
import "../../styles.css";
import { Auth } from "aws-amplify";

export const Dashboard = (props) => {
  const [policies, setPolicies] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [policySummary, setPolicySummary] = useState({
    policies_sold: 0,
    total_payout: 0,
    total_sum_insured: 0,
  });

  useEffect(() => {
    async function fetchData(){
    const { accessToken: { jwtToken } } = await Auth.currentSession();
    // Fetching policies data
    fetch("/api/monitor/getPolicies", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => setPolicies(data));

    // Fetching policy summary data
    fetch("/api/monitor/getAllPolicySummary", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => setPolicySummary(data));
  }
  fetchData();
  }, []);

  const handleEvent = (params, event, details) => {
    const selectedPolicyNumber = params.row.policy_number; // Get the policy number of the clicked row
    props.setselectedPolicyNo(selectedPolicyNumber);
    props.setselectedPolicymasterHolder( params.row.master_holder);
    props.setMenu("Monitor Details");
  };

  const filteredPolicies = policies.filter(policy =>
    policy.master_holder.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const statusFilterOptions = [
    { value: "Live", label: "Live" },
    { value: "Closed", label: "Closed" },
  ];

  const columns = [
    { field: "policy_number", headerName: "Policy #", width: 80,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
    },
    { field: "master_holder", headerName: "Policy Holder", width: 130,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
    },
    { field: "endorsee_count", headerName: "Endorsee Count", width: 90,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
    },
    { field: "status", headerName: "Status", width: 90,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
      filterable: true,
      type: "singleSelect",
      valueOptions: statusFilterOptions.map((option) => option.value),
    },
    {
      field: "policy_start_date",
      headerName: "Policy Start Date",
      width: 120,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
    },
    {
      field: "policy_end_date",
      headerName: "Policy End Date",
      width: 110,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
    },
    {
      field: "sum_insured",
      headerName: "Sum Insured",
      type: "number",
      width: 110,
      renderCell: (params) => <span>{params.value}</span>, // No hover effect
    },
    {
      field: "strike_price",
      headerName: "Strike Price",
      type: "number",
      width: 90,
      renderCell: (params) => (
        <div title={`${params.value} Rs/quintals`}>{params.value}</div> // Custom hover text
      ),
    },
    { field: "uom", headerName: "Unit", width: 70 },
    {
      field: "actual_amount",
      headerName: "Actual Amount",
      width: 110,
      renderCell: (params) => (
        <div title={`${params.value} quintals`}>{params.value}</div> // Custom hover text
      ),
    },
    {
      field: "payout_amount",
      headerName: "Payout Amount",
      width: 110,
      renderCell: (params) => (
        <div title={`${params.value} quintals`}>{params.value}</div> // Custom hover text
      ),
    },
    {
      field: "trigger",
      headerName: "Trigger",
      width: 80,
      renderCell: (params) => (
        <span
          style={{
            display: "inline-block",
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: params.value === "true" ? "#f44336" : "#4caf50", // Red for true, Green for false
          }}
        ></span>
      ),
    },
  ];

  const greenPatchStyle = {
    backgroundColor: "#E1F0FF",
    paddingTop: "5px",
    borderColor: "#0880EA",
    borderRadius: "16px",
  };

  return (
    <>
      <div
        className="row "
        style={{
          overflowY: "scroll",
          background: "#F2F2F0",
          paddingLeft: "100px",
        }}
      >
        <div className="col-md-11" style={{ width: "98%" }}>
          <div className="row justify-content-center mt-4 mb-4">
            <div className="col-md-4">
              <Card color="primary" outline>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <div className="row policy-value">{policySummary.policies_sold}</div>
                      <div className="row policy-label">Policies Sold</div>
                    </div>
                    <div className="col-md-2" style={greenPatchStyle}>
                      <img src="Policies.svg" alt="Policies Sold" />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card color="primary" outline>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-md-4 policy-value">
                      <div className="row policy-value">₹{policySummary.total_payout}</div>
                      <div className="row policy-label">Total Payout</div>
                    </div>
                    <div className="col-md-2" style={greenPatchStyle}>
                      <img src="TotalPayout.svg" alt="TotalPayout " />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card color="primary" outline>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-md-5">
                      <div className="row policy-value">₹{policySummary.total_sum_insured}</div>
                      <div className="row policy-label">
                        Total Sum Insured
                      </div>
                    </div>
                    <div className="col-md-2" style={greenPatchStyle}>
                      <img src="SumInsured.svg" alt="SumInsured " />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <Card>
                <div className="container mt-2">
                  <div className="row justify-content-between">
                    <div className="col-6 col-md-9">
                      <div className="row">
                        <div className="col-md-4">
                          <InputGroup style={{ height: "40px" }}>
                            <InputGroupText>
                              <BiSearch size={20} />
                            </InputGroupText>
                            <Input
                              name="search"
                              placeholder="Search by master"
                              type="text"
                              className="custom-select"
                              value={searchQuery} // Controlled input value
                              onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="row">
                        <div className="col-md-10">
                          <Button
                            className="ps-5 pe-5"
                            color="primary"
                            onClick={() => {
                              props.setMenu("Policy");
                            }}
                          >
                            Create Policy
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-between mt-4">
                    <div className="col-6 col-md-12">
                      <DataGrid
                        style={{ height: 510 }}
                        rows={filteredPolicies} // Pass filtered data to DataGrid
                        columns={columns}
                        checkboxSelection
                        onRowClick={handleEvent}
                        getRowId={(row) => row["policy_number"]}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};