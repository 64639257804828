const awsmobile = {
  "aws_project_region": "ap-south-1",
  "aws_cognito_identity_pool_id": "ap-south-1:a7cfb9c7-2df0-4bd8-a173-cb886b322ad1",
  "aws_cognito_region": "ap-south-1",
  "aws_user_pools_id": "ap-south-1_YRZCod0VX",
  "aws_user_pools_web_client_id": "4ql0fis0ttt2aht8oahtfbg79k",
  "oauth": {},
  // "aws_mobile_analytics_app_region": "ap-south-1",
  // "aws_mobile_analytics_app_id": "9b7ffa0f54f74ef7856b33d0daa48014"
};
export default awsmobile;
