import React, { useState } from "react";
import "../styles.css";
import { Nav, NavItem } from "reactstrap";
import { IoIosSearch } from "react-icons/io";
import { TbPresentationAnalytics } from "react-icons/tb";
import { AiOutlineBarChart } from "react-icons/ai";

export const Sidebar = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("Explore");

  const handleClick = (menu) => {
    setSelectedMenu(menu);
    props.setMenu(menu);
  };
//console.log(props.menu)
  return (
    <>
      <div
        className="sidebar-sticky d-none d-md-block"
        style={{ marginTop: "48px", zIndex: 1000 }}
      >
        <Nav className="flex-column" style={{ listStyle: "none" }}>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Explore");
            }}
          >
            <NavItem
              className="mb-2 d-flex align-items-center justify-content-center"
              style={{
                cursor: "pointer",
                marginLeft: 10,
                borderRadius: "5px",
                marginBottom: "24px",
                marginTop: "5px",
              }}
              onClick={() => handleClick("Explore")}
            >
              <div className="d-flex align-items-center flex-column">
                <div
                  className="menuicon"
                  style={{
                    background: selectedMenu === "Explore" ? "#0880EA" : "none",
                  }}
                >
                  {selectedMenu === "Explore" ? (
                    <IoIosSearch
                      size={24}
                      style={{ margin: 5, color: "white" }}
                    />
                  ) : (
                    <IoIosSearch size={24} style={{ margin: 5 }} />
                  )}
                </div>
                <div className="menutext">Explore</div>
              </div>
            </NavItem>
          </div>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Pricing");
            }}
          >
            <NavItem
              className="mb-2 d-flex align-items-center justify-content-center"
              style={{
                cursor: "pointer",
                marginLeft: 10,
                borderRadius: "5px",
                marginBottom: "24px",
              }}
              onClick={() => handleClick("Pricing")}
            >
              <div className="d-flex align-items-center flex-column">
                <div
                  className="menuicon"
                  style={{
                    background: selectedMenu === "Pricing" ? "#0880EA" : "none",
                  }}
                >
                  {selectedMenu === "Pricing" ? (
                    <AiOutlineBarChart
                      size={24}
                      style={{ margin: 5 }}
                      color="white"
                    />
                  ) : (
                    <AiOutlineBarChart size={24} style={{ margin: 5 }} />
                  )}
                </div>{" "}
                <div className="menutext">Pricing</div>
              </div>
            </NavItem>
          </div>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Monitor");
            }}
          >
            <NavItem
              className="mb-2 d-flex align-items-center justify-content-center"
              style={{
                cursor: "pointer",
                marginLeft: 10,
                borderRadius: "5px",
                marginBottom: "24px",
              }}
              onClick={() => handleClick("Monitor")}
            >
              <div className="d-flex align-items-center flex-column">
                <div
                  className="menuicon"
                  style={{
                    background: selectedMenu === "Monitor" ? "#0880EA" : "none",
                  }}
                >
                  {selectedMenu === "Monitor" ? (
                    <TbPresentationAnalytics
                      size={24}
                      style={{ margin: 5, color: "white" }}
                    />
                  ) : (
                    <TbPresentationAnalytics size={24} style={{ margin: 5 }} />
                  )}
                </div>{" "}
                <div className="menutext">Monitor</div>
              </div>
            </NavItem>
          </div>
        </Nav>
      </div>
    </>
  );
};
