import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

export const PriceComparison = ({ data }) => {
  // Helper function to convert data format for Highcharts
  const transformData = (inputData) => {
    return inputData.map((point) => [point.date * 1000, point.price]);
  };

  const options = {
    title: {
      text: "",
      align: "left",
    },

    yAxis: {
      title: {
        text: "Rs/Quintal",
      },
    },

    xAxis: {
      type: "datetime", // Use datetime axis for time-based data
      accessibility: {
        rangeDescription: "Range: 2020 to 2022",
      },
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: [
      {
        name: "Agmarknet",
        data: data["Agmarknet"] ? transformData(data["Agmarknet"]) : [], // Check if data exists
        color: "#18794E", // Use the specified color
      },
      {
        name: "e-NAM",
        data: data["e-NAM"] ? transformData(data["e-NAM"]) : [], // Check if data exists
        color: "#0B68CB", // Use the specified color
      },
      {
        name: "NCDEX",
        data: data["NCDEX"] ? transformData(data["NCDEX"]) : [], // Check if data exists
        color: "#544FC5", // Use the specified color
      },
      {
        name: "Strike Price",
        data: data["Strike Price"] ? transformData(data["Strike Price"]) : [], // Check if data exists
        color: "#FF0000", // Example color, adjust as needed
        dashStyle: "Dash", // Optional: Use a different style for the line
      },
    ],

    credits: {
      enabled: false,
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
