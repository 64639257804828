import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import PriceTrend from "./PriceTrend";
import MonthlyAvgPriceGraph from "./MonthlyAvgPriceGraph";
import HeatMap from "./HeatMap";
import { Auth } from "aws-amplify";

const CompareExplore = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [priceTrendData, setPriceTrendData] = useState(null);
  const [monthlyAvgVsArrivalData, setMonthlyAvgVsArrivalData] = useState(null);
  const [heatMapData, setHeatMapData] = useState(null);
  const [monthlyAveragePricesData, setMonthlyAveragePricesData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => 
     {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const priceTrendResponse = await fetch(
          `/api/explore/priceGraph?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}&crop_variety=${props.cropVariety}&source=${props.priceSource}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
          }
        );
        setPriceTrendData(await priceTrendResponse.json());

        const monthlyAvgVsArrivalResponse = await fetch(
          `/api/explore/monthlyAverageVsArrival?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}&crop_variety=${props.cropVariety}&source=${props.priceSource}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
          }
        );
        setMonthlyAvgVsArrivalData(await monthlyAvgVsArrivalResponse.json());

        const heatMapResponse = await fetch(
          `/api/explore/heatMap?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}&crop_variety=${props.cropVariety}&source=${props.priceSource}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
          }
        );
        setHeatMapData(await heatMapResponse.json());

        const monthlyAveragePricesResponse = await fetch(
          `/api/explore/monthlyAveragePrices?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}&crop_variety=${props.cropVariety}&source=${props.priceSource}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
          }
        );
        setMonthlyAveragePricesData(await monthlyAveragePricesResponse.json());
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.priceSource,props.cropName,props.marketName,props.policyStartYear,props.policyEndYear,props.monitoringStartMonth,props.monitoringEndMonth,props.cropVariety]);

  const renderPriceTable = () => {
    const years = Array.from(new Set(monthlyAveragePricesData.map((d) => d.year)));
    
    // Extract unique months available in the data
    const monthsWithData = Array.from(new Set(monthlyAveragePricesData.map((d) => d.month)));
  
    // Organize price data by month and year
    const priceData = {};
    monthlyAveragePricesData.forEach(({ month, year, price }) => {
      if (!priceData[month]) priceData[month] = {};
      priceData[month][year] = price;
    });
  
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 420, overflowY: "auto" }}>
        <Table stickyHeader size="small" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "4px" }}>Month</TableCell>
              {years.map((year) => (
                <TableCell key={year} style={{ fontWeight: "bold", border: "1px solid #ddd", textAlign: "center", padding: "4px" }}>
                  {year}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {monthsWithData.map((month) => (
              <TableRow key={month}>
                <TableCell style={{ border: "1px solid #ddd", fontWeight: "bold", padding: "5px" }}>{month}</TableCell>
                {years.map((year) => (
                  <TableCell
                    key={`${month}-${year}`}
                    style={{ border: "1px solid #ddd", textAlign: "center", padding: "5px" }}
                  >
                    {priceData[month] && priceData[month][year] ? priceData[month][year] : '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Generate Tabs"
      >
        <Tab label="Data Validation" sx={{ textTransform: "capitalize", fontSize: "16px" }} />
        <Tab label="Risk Analytics" sx={{ textTransform: "capitalize", fontSize: "16px" }} />
        <Tab label="Outlook" sx={{ textTransform: "capitalize", fontSize: "16px" }} />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {tabValue === 0 && (
          <>
            {props.showCompare ? (
              <Box>
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <>
                    {heatMapData ? (
                      <>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "left",
                            marginBottom: "20px",
                          }}
                        >
                          Percentage share of good data days
                        </div>
                        <HeatMap data={heatMapData} />
                      </>
                    ) : (
                      <Box sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "30px",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                    {priceTrendData?(<PriceTrend data={priceTrendData} priceSource={props.priceSource}/>):('')}
                    
                  </>
                )}
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  height: "90vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("Agtuall_compare_map.svg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              >
                <div className="selectmodule">
                  Fill the Input Fields from the left panel to start exploring Data & Charts
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 1 && (
          <>
            {props.showCompare ? (
              <div>
                <div style={{ fontSize: "16px", fontWeight: 500, textAlign: "left", marginBottom: "20px" }}>
                  Average Monthly Prices ({props.policyStartYear}-{props.policyEndYear})
                </div>
                {monthlyAvgVsArrivalData != null && (
                  <MonthlyAvgPriceGraph data={monthlyAvgVsArrivalData} />
                )}
                {/* Render the compact, scrollable price table */}
                {monthlyAveragePricesData.length > 0 && renderPriceTable()}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  height: "90vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("Agtuall_compare_map.svg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              >
                <div className="selectmodule">
                  Fill the Input Fields from the left panel to start exploring Data & Charts
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 2 && <Typography>Outlook</Typography>}
      </Box>
    </Box>
  );
};

export default CompareExplore;
