import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

const MonthlyAvgPriceGraph = ({ data }) => {
  // Convert the data to Highcharts-compatible format
  if (data){
  const prices = data.map(item => [item.date, item.price]);
  const arrivals = data.map(item => [item.date, item.arrival]);

  const options = {
    series: [
      {
        name: "Prices (INR Quintals)",
        type: "line", // Line graph for prices
        data: prices,
        color: "#0880EA", // Line color
        yAxis: 0, // Primary yAxis for Prices
      },
      {
        name: "Arrivals (Tons)",
        type: "column", // Bar graph for arrivals
        data: arrivals,
        color: "#9BCCB4", // Bar color
        yAxis: 1, // Secondary yAxis for Arrivals
      },
    ],
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "Average of <b>" +
          moment(this.x).format("MMM YYYY") +
          "</b>: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    yAxis: [
      {
        // Primary yAxis for Prices
        title: {
          text: "Prices (INR Quintals)",
          style: {
            color: "#0880EA", // Match with line color
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "#0880EA", // Match with line color
          },
        },
      },
      {
        // Secondary yAxis for Arrivals
        title: {
          text: "Arrivals (Tons)",
          style: {
            color: "#9BCCB4", // Match with bar color
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "#9BCCB4", // Match with bar color
          },
        },
        opposite: true,
      },
    ],
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000, // Interval set to 1 month
      labels: {
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(this.value).format("MMM YYYY");
        },
      },
    },
  };


  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
}

export default MonthlyAvgPriceGraph;
