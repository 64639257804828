import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SummaryGraph = ({ data }) => {
  // Extract years and prices from the data prop
  const categories = data.map(item => item.year.toString());
  const seriesData = data.map(item => item.payout);

  const options = {
    chart: {
      type: 'column',
      height: 340
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: categories,
      title: {
        text: ''
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Payout (%)',
        align: 'middle',
        rotation: -90,
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return `<b>Payout (%):</b> ${this.y}`;
      }
    },
    series: [{
      name: 'Payout (%)',
      data: seriesData, 
      color: '#2187D6'
    }],
    credits: {
      enabled: false
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SummaryGraph;
