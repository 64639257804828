import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import Generate from "./Generate";
import "../../Pricing.css";
import { Auth } from "aws-amplify";

const Pricing = () => {
  const [cropName, setCropName] = useState("Soyabean");
  const [cropVariety, setCropVariety] = useState("Yellow"); // Default variety for Soyabean
  const [marketName, setMarketName] = useState("Indore");
  const [priceSource, setPriceSource] = useState("Agmarknet");
  const [priceUnit, setPriceUnit] = useState(null);
  const [strikeMethod, setstrikeMethod] = useState(null);
  const [StrikeStartDate, setStrikeStartDate] = useState("");
  const [error, setError] = useState("");
  const [StrikeEndDate, setStrikeEndDate] = useState("");
  const [monitoringStartDate, setMonitoringStartDate] = useState("");
  const [monitoringEndDate, setMonitoringEndDate] = useState("");
  const [strikePrice, setStrikePrice] = useState("");
  const [strikePricePercentile, setStrikePricePercentile] = useState("");
  const [exitPrice, setExitPrice] = useState("");
  const [deTrendData, setDeTrendData] = useState("no");
  const [showGenerate, setShowGenerate] = useState(false); // State to control Generate component visibility
  const [generateclicked, setgenerateclicked] = useState(1); // State to control Generate component visibility
  const [endDateError, setEndDateError] = useState(false);
  const [historicalPrices, setHistoricalPrices] = useState([]); // New state for historical price data
  const [editedHistoricalPrices, setEditedHistoricalPrices] = useState([]); // State to store edited prices
  const [currentStrikePrice, setcurrentStrikePrice] = useState("");
  const [insuranceType, setInsuranceType] = useState(null);
  const [movingAvgWindow, setmovingAvgWindow] = useState(null);
  const [postHarvestSelected, setpostHarvestSelected] = useState(false);
  const [bucketWindow, setbucketWindow] = useState(null);
  const [typeofRisk, settypeofRisk] = useState(null);

  const cropNameOptions = [
    { value: "Red Chilly", label: "Red Chilly" },
    { value: "Soyabean", label: "Soyabean" },
    { value: "Wheat", label: "Wheat" },
    { value: "Maize", label: "Maize" },
    { value: "Dry Ginger", label: "Dry Ginger" },
    { value: "Green Ginger", label: "Green Ginger" },
    { value: "Bengal Gram (chana)", label: "Bengal Gram (chana)"}

  ];

  // Conditional options for Crop Variety based on selected Crop Name
  const cropVarietyOptions = {
    "Red Chilly": [{ value: "Red", label: "Red" }],
    "Soyabean": [{ value: "Yellow", label: "Yellow" }],
    "Wheat": [{ value: "Other", label: "Other" },
      { value: "Mill Quality", label: "Mill Quality" },
      { value: "Wheat", label: "Wheat" },
      { value: "Wheat Mix", label: "Wheat Mix" },
      { value: "Malwa Shakti", label: "Malwa Shakti" },
      { value: "Local", label: "Local" },
      { value: "Lokwan", label: "Lokwan" }],
    "Maize":[{ value: "Local", label: "Local" },{ value: "Hybrid", label: "Hybrid" },{ value: "Deshi Red", label: "Deshi red" }],
    "Dry Ginger":[{value: "Other", label: "Other" }],
    "Green Ginger":[{value: "Green Ginger", label: "Green Ginger" }],
    "Bengal Gram (chana)":[{value: "Other", label: "Other" }],
  };
  const insurance_type_options = [
    { value: "Sowing", label: "Sowing" },
    { value: "Post-Harvest", label: "Post-Harvest" },
  ];
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setMonitoringStartDate(selectedStartDate);

    // Re-validate end date when start date changes
    if (
      monitoringEndDate &&
      new Date(selectedStartDate) > new Date(monitoringEndDate)
    ) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setMonitoringEndDate(selectedEndDate);

    // Check if the end date is earlier than the start date
    if (
      monitoringStartDate &&
      new Date(selectedEndDate) < new Date(monitoringStartDate)
    ) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };

  const handleStrikeStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStrikeStartDate(selectedStartDate);

    // Re-validate end date when start date changes
    if (
      StrikeEndDate &&
      new Date(selectedStartDate) > new Date(StrikeEndDate)
    ) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };
  const handleStrikeEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setStrikeEndDate(selectedEndDate);

    // Check if the end date is earlier than the start date
    if (
      StrikeStartDate &&
      new Date(selectedEndDate) < new Date(StrikeStartDate)
    ) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };
  const isFormValid =
    cropName &&
    marketName &&
    StrikeStartDate &&
    StrikeEndDate &&
    priceSource &&
    monitoringStartDate &&
    monitoringEndDate &&
    exitPrice &&
    priceUnit;

  const handleClear = () => {
    setCropName(null);
    setCropVariety(null);
    setMarketName(null);
    setPriceSource(null);
    setMonitoringStartDate("");
    setMonitoringEndDate("");
    setStrikePrice("");
    setStrikePricePercentile("");
    setStrikeStartDate("");
    setStrikeEndDate("");
    setExitPrice("");
    setDeTrendData("no");
    setShowGenerate(false);
    setgenerateclicked(1);
    setHistoricalPrices([]);
    setEditedHistoricalPrices([]);
    setcurrentStrikePrice("");
    setInsuranceType("");
    setmovingAvgWindow(null);
  };

  const handleGenerateStrikePrice = async () => {
    const { accessToken: { jwtToken } } = await Auth.currentSession();

    if (isFormValid) {
      try {
        // Prepare the request body, conditionally including `percentile`
        const requestBody = {
          start_date: StrikeStartDate,
          end_date: StrikeEndDate,
          source: priceSource,
          commodity: cropName,
          market_name: marketName,
          strike_price_type: strikeMethod,
          crop_variety: cropVariety,
          ...(strikeMethod === "Percentile" && {
            percentile: parseInt(strikePricePercentile, 10),
          }),
        };

        // Use fetch to call the API
        const response = await fetch("/api/pricing/historicalStrikePrice", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwtToken,
          },
          body: JSON.stringify(requestBody),
        });

        // Parse the response
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const resultData = await response.json();
        setStrikePrice(resultData.strike_price);
        setHistoricalPrices(resultData); // Set the data from the API response
        if(resultData.length !== 0)
          setEditedHistoricalPrices(resultData)
        else
          setEditedHistoricalPrices([])
      } catch (error) {
        setHistoricalPrices([])
        console.error("Error fetching strike price:", error);
        setEditedHistoricalPrices([])
      }
    }
  };


  const handleGenerate = async () => {
    if (isFormValid) {
      try {
        if(editedHistoricalPrices.length === 0)
          handleGenerateStrikePrice()
        // Set states after successful API call
        setShowGenerate(true);
        setgenerateclicked((prev) => prev + 1);
      } catch (error) {
        console.error("Error fetching strike price:", error);
      }
    }
  };

  useEffect(() => {
    // Logic to generate historical strike price table
    handleGenerateStrikePrice()
  }, [
    StrikeStartDate,
    StrikeEndDate,
    priceSource,
    cropName,
    marketName,
    strikeMethod,
    strikePricePercentile,
    insuranceType,
    cropVariety
  ]);

  const handlePriceChange = (index, newValue) => {
    setEditedHistoricalPrices((prevPrices) => {
      const updatedPrices = [...prevPrices];
      updatedPrices[index].price = newValue;
      return updatedPrices;
    });
  };

  const handleWeightChange = (index, newValue) => {
    setEditedHistoricalPrices((prevPrices) => {
      const updatedPrices = [...prevPrices];
      updatedPrices[index].weight = newValue;
      return updatedPrices;
    });
  };


  return (
    <div className="compare-pannel">
      <div>
        <div className="exploretext">Premium Calculation</div>
        <div className="compareecondarytext">
          Fill the required input fields to calculate the pure premium
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Crop Name <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Crop Name"
            value={cropName ? { label: cropName, value: cropName } : null}
            onChange={(selectedOption) => {
              setCropName(selectedOption.value);
              setCropVariety(null);
              setMarketName(null);
              setPriceSource(null);
            }}
            options={cropNameOptions}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Crop Variety <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Crop Variety"
            value={
              cropVariety ? { label: cropVariety, value: cropVariety } : null
            }
            onChange={(selectedOption) => {
              setCropVariety(selectedOption.value);
            }}
            options={cropVarietyOptions[cropName] || []} // Dynamically set options based on cropName
            isDisabled={!cropName} // Disable until a crop name is selected
          />
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Market Name <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Market Name"
            value={marketName ? { label: marketName, value: marketName } : null}
            onChange={(selectedOption) => setMarketName(selectedOption.value)}
            options={
              cropName === "Red Chilly"
                ? [{ value: "Guntur", label: "Guntur" }]
                : cropName === "Soyabean"
                ? [{ value: "Indore", label: "Indore" }]
                : cropName === "Wheat"
                ? [{ value: "Indore", label: "Indore" }]
                : cropName === "Maize"
                ? [{ value: "Gulabbagh", label: "Gulabbagh" },{ value: "Nizamabad", label: "Nizamabad" }]
                : cropName === "Dry Ginger"
                ? [{ value: "Kalpetta", label: "Kalpetta" }]
                : cropName === "Green Ginger"
                ? [{ value: "Kalpetta", label: "Kalpetta" }]
                : cropName === "Bengal Gram (chana)"
                ? [{ value: "Mumbai", label: "Mumbai" }]
                : []
            }
            isDisabled={!cropName}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Price Source <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Price Source"
            value={
              priceSource ? { label: priceSource, value: priceSource } : null
            }
            onChange={(selectedOption) => setPriceSource(selectedOption.value)}
            options={
              cropName === "Red Chilly"
                ? [
                    { value: "e-NAM", label: "e-NAM" },
                    { value: "Agmarknet", label: "Agmarknet" },
                  ]
                : cropName === "Soyabean"
                ? [
                    { value: "Agmarknet", label: "Agmarknet" },
                    { value: "NCDEX", label: "NCDEX" },
                  ]
                  : cropName === "Wheat"
                  ? [
                      { value: "Agmarknet", label: "Agmarknet" },
                    ]
                    : cropName === "Maize"
                    ? [
                        { value: "Agmarknet", label: "Agmarknet" },
                      ]
                      : cropName === "Dry Ginger"
                    ? [
                        { value: "Agmarknet", label: "Agmarknet" },
                      ]
                      : cropName === "Green Ginger"
                    ? [
                        { value: "Agmarknet", label: "Agmarknet" },
                      ]
                      : cropName === "Bengal Gram (chana)"
                    ? [
                        { value: "Agmarknet", label: "Agmarknet" },
                      ]
                : []
            }
          />
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Type of Insurance <span className="mandatory"></span>
          </h2>
          <Select
            className="custom-select"
            options={insurance_type_options}
            placeholder="Type of Insurance"
            onChange={(obj) => {
              setInsuranceType(obj.value);
              setEditedHistoricalPrices([]);
              setpostHarvestSelected(obj.value === "Post-Harvest"); // Update based on selection
            }}
          />
        </div>
        {insuranceType === "Post-Harvest" && (
          <div className="download-container">
            <div className="date-item">
              <h2 className="select-label">
                Moving Average Window (Days(1-30)){" "}
                <span className="mandatory">*</span>
              </h2>
              <TextField
                id="strikePricepercentile"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                error={!!error}
                helperText={error}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!Number.isInteger(value) || value < 1 || value > 30) {
                    setError("Please enter an integer between 1 and 30.");
                    setmovingAvgWindow(""); // Clear the state if input is invalid
                  } else {
                    setError(""); // Clear the error if input is valid
                    setmovingAvgWindow(value);
                  }
                }}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="select-label">
                Bucket Window <span className="mandatory">*</span>
              </h2>
              <TextField
                id="strikePricepercentile"
                fullWidth
                type="number"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setbucketWindow(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        )}
 <div className="download-container">
          <h2 className="select-label">
            Type of Risk <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Type of risk"
            value={typeofRisk ? { label: typeofRisk, value: typeofRisk } : null}
            onChange={(selectedOption) => settypeofRisk(selectedOption.value)}
            options={[
              { value: "Upside", label: "Upside" },
              { value: "Downside", label: "Downside" },
            ]}
          />
          </div>
        {/* Monitoring Duration */}
        <div className="download-container">
          <h2 className="select-label">
            Monitoring Duration <span className="mandatory">*</span>
          </h2>
          <div className="date-row">
            <div className="date-item">
              <h2 className="date-label">Start Date</h2>
              <TextField
                id="monitoringStartDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={monitoringStartDate}
                onChange={handleStartDateChange}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="date-label">End Date</h2>
              <TextField
                id="monitoringEndDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={monitoringEndDate}
                onChange={handleEndDateChange}
                error={endDateError} // Shows red border if there's an error
                helperText={
                  endDateError
                    ? "End Date cannot be earlier than Start Date"
                    : ""
                }
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": {
                    height: "45px",
                    fontSize: "14px",
                    borderColor: endDateError ? "red" : "inherit",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Historical Strike Calculation <span className="mandatory">*</span>
          </h2>
          <div className="date-row">
            <div className="date-item">
              <h2 className="date-label">Start Date</h2>
              <TextField
                id="StrikeStartDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={StrikeStartDate}
                onChange={handleStrikeStartDateChange}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="date-label">End Date</h2>
              <TextField
                id="StrikeEndDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={StrikeEndDate}
                onChange={handleStrikeEndDateChange}
                error={endDateError}
                helperText={
                  endDateError
                    ? "End Date cannot be earlier than Start Date"
                    : ""
                }
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": {
                    height: "45px",
                    fontSize: "14px",
                    borderColor: endDateError ? "red" : "inherit",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Price Unit <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Price unit"
            value={priceUnit ? { label: priceUnit, value: priceUnit } : null}
            onChange={(selectedOption) => setPriceUnit(selectedOption.value)}
            options={[
              { value: "Rs/Quintal", label: "Rs/Quintal" },
              { value: "Rs/kg", label: "Rs/kg" },
            ]}
          />
        </div>
        <div className="download-container">
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">
                Current Strike Price <span className="mandatory">*</span>
              </h2>
              <TextField
                id="StrikePrice"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={currentStrikePrice}
                onChange={(e) => setcurrentStrikePrice(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="select-label">
                Current Exit Price <span className="mandatory">*</span>
              </h2>
              <TextField
                id="exitPrice"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={exitPrice}
                onChange={(e) => setExitPrice(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        </div>
       
        <div className="download-container">
          <h2 className="select-label">
            Historical Strike Method <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Strike Method"
            value={
              strikeMethod ? { label: strikeMethod, value: strikeMethod } : null
            }
            onChange={(selectedOption) => setstrikeMethod(selectedOption.value)}
            options={[
              { value: "Average", label: "Average" },
              { value: "Percentile", label: "Percentile" },
            ]}
          />
        </div>

        {strikeMethod === "Percentile" && (
          <div className="download-container">
            <div className="date-item">
              <h2 className="select-label">
                Strike Percentile <span className="mandatory">*</span>
              </h2>
              <TextField
                id="strikePricepercentile"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setStrikePricePercentile(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        )}

        <div className="download-container" style={{ marginBottom: "80px" }}>
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">Remove outlier</h2>
              <RadioGroup
                row
                value={deTrendData}
                onChange={(event) => setDeTrendData(event.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
          </div>
        </div>
        {historicalPrices.length > 0 && (
          <div className="download-container" style={{ marginBottom: "180px" }}>
            <h2 className="select-label">Historical Strike Prices</h2>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                    Year
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                    Price
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                    Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                {editedHistoricalPrices.map((item, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                      {item.year}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                      <input
                        type="number"
                        value={item.price}
                        onChange={(e) =>
                          handlePriceChange(index, parseFloat(e.target.value))
                        }
                        style={{ width: "100%" }}
                      />
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                      <input
                        type="number"
                        value={item.weight}
                        onChange={(e) =>
                          handleWeightChange(index, parseFloat(e.target.value))
                        }
                        style={{ width: "100%" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div
          className="button-container"
          style={{
            position: "fixed",
            bottom: "0",
            width: "380px",
            backgroundColor: "white",
            zIndex: "1000",
            padding: "15px 20px",
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #ccc",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F1F5F9",
              color: "#000",
              marginRight: "20px",
            }}
            onClick={handleClear}
          >
            Clear
          </Button>
          
          <Button
            variant="contained"
            style={{
              backgroundColor: isFormValid ? "#0880EA" : "#ccc",
              color: "#fff",
            }}
            disabled={!isFormValid}
            onClick={handleGenerate}
          >
            Generate Output
          </Button>
        </div>
      </div>

      {showGenerate && (
        <div
          className="generate-panel"
          style={{
            position: "fixed",
            right: "0",
            top: "0",
            width: "calc(100vw - 470px)",
            height: "100vh",
            backgroundColor: "#fff",
            zIndex: "1000",
            padding: "24px",
            borderLeft: "1px solid rgba(26, 26, 0, 0.16)",
            overflow: "auto",
            marginTop: "40px",
          }}
        >
          <Generate
            showGenerate={showGenerate}
            cropName={cropName}
            cropVariety={cropVariety}
            monitoringStartDate={monitoringStartDate}
            monitoringEndDate={monitoringEndDate}
            strikePrice={strikePrice}
            exitPrice={exitPrice}
            currentStrikePrice={currentStrikePrice}
            priceSource={priceSource}
            priceUnit={priceUnit}
            marketName={marketName}
            generateclicked={generateclicked}
            editedHistoricalPrices={editedHistoricalPrices}
            postHarvestSelected={postHarvestSelected}
            insuranceType={insuranceType}
            movingAvgWindow={movingAvgWindow}
            bucketWindow={bucketWindow}
            typeofRisk={typeofRisk}
          />
        </div>
      )}
    </div>
  );
};

export default Pricing;
