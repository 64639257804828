import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';

// Initialize the Heatmap module
HighchartsHeatmap(Highcharts);

const HeatMap = ({ data }) => {
  // Ensure data is valid
  if (!data || !data.valuesArray) {
    return <div>Error: No data available to display the heatmap.</div>;
  }

  // Prepare the series data for the heatmap
  const seriesData = data.valuesArray.map(([x, y, value]) => [x, y, value]);

  // Highcharts configuration
  const options = {
    chart: {
      type: 'heatmap',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: data.x_axis,
     
    },
    yAxis: {
      categories: data.y_axis,
      title: {
        text: '',
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      itemStyle: {
        color: 'rgba(1, 97, 158, 0.2)',
      },
    },
    tooltip: { enabled:false },
    credits: false,
    colorAxis: {
      min: 0,
      max: 100,
      minColor: 'white',
      maxColor: '#01619E',
    },
    series: [
      {
        name: '',
        borderWidth: 1,
        data: seriesData,
        dataLabels: {
          enabled: true,
          color: '#000000',
        },
        
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HeatMap;
